import { AriaAttributes, ReactNode } from "react";

type Props = {
  children: ReactNode;
} & Omit<AriaAttributes, "role">;

export const ErrorBlock = ({ children, ...ariaAttributes }: Props) => {
  return (
    <div
      className="bg-alert-light-2 border-alert-dark-1 text-alert pt-sm pb-sm pl-md pr-md m-0 rounded-sm border text-center text-xs"
      role="alert"
      {...ariaAttributes}
    >
      {children}
    </div>
  );
};

import { APIError } from "libs/api/client";
import { FEATURES, isEnableFeature } from "libs/features";
import { useTransaction } from "libs/hooks/useTransaction";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecaptchaV2 } from "react-hook-recaptcha-v2";
import { login } from "../client_apis";
import { LoginFormValues } from "../types";

const defaultValues: LoginFormValues = {
  email: "",
  password: "",
  remember_me: "",
  recaptcha_response_token: null,
};

type Params = {
  onLoginSucceeded: () => void;
  onAccountLocked: () => void;
};

export const useLoginForm = ({ onLoginSucceeded, onAccountLocked }: Params) => {
  const [error, setError] = useState<
    null | "invalid" | "locked" | "network" | "invitation_inactive"
  >(null);
  const errorMessage = useMemo(() => {
    if (error == null) {
      return undefined;
    } else if (error === "invalid") {
      return "メールアドレスかパスワードに誤りがあります";
    } else if (error === "network") {
      return "通信エラーが発生しました";
    } else if (error === "invitation_inactive") {
      return "登録済みなため、ログインをお願いします";
    }
  }, [error]);
  const form = useForm<LoginFormValues>({
    shouldUnregister: false,
    defaultValues,
  });
  const { watch, handleSubmit, setValue } = form;

  const formFullFilled =
    watch("email").length > 0 &&
    watch("password").length > 0 &&
    // テスト環境では、recaptcha_response_token が null でも送信できるようにする
    (!isEnableFeature(FEATURES.TEST)
      ? watch("recaptcha_response_token") != null
      : true);

  // reCAPTCHA v2
  const handleReCAPTCHAChange = useCallback(
    (token: string | null) => {
      setValue("recaptcha_response_token", token);
    },
    [setValue],
  );

  const { recaptchaRef } = useRecaptchaV2({
    sitekey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || "",
    size: "normal",
    callback: handleReCAPTCHAChange,
    expiredCallback: () => {
      setValue("recaptcha_response_token", null);
    },
  });

  const [submitStatus, handleSubmitImpl] = useTransaction(
    async (props: typeof defaultValues) => {
      setError(null);
      // テスト環境では、recaptcha_response_token が null でも送信できるようにする
      if (
        !isEnableFeature(FEATURES.TEST) &&
        props.recaptcha_response_token == null
      ) {
        return;
      }
      await login({
        delivery_client: {
          email: props.email,
          password: props.password,
          remember_me: props.remember_me === "1",
        },
        recaptcha_response_token: props.recaptcha_response_token || undefined,
      });
    },
  );

  // ログイン成功したらコールバックを呼び出す
  useEffect(() => {
    if (submitStatus.complete) {
      onLoginSucceeded();
    }
  }, [submitStatus.complete, onLoginSucceeded]);

  // ログイン失敗したら適切にハンドリングする
  useEffect(() => {
    if (submitStatus.error == null) {
      return;
    }

    const e = submitStatus.error;
    if (e instanceof APIError) {
      if (e.type === "ApiError::DeliveryClient::AccountLocked") {
        onAccountLocked();
      } else if (e.status === 401) {
        setError("invalid");
      } else {
        setError("network");
      }
    }
  }, [submitStatus.error, onAccountLocked]);

  return {
    form,
    formFullFilled,
    errorMessage,
    submitStatus,
    recaptchaRef,
    handleSubmit: handleSubmit(handleSubmitImpl),
  };
};

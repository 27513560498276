import { Colors } from "components/colors";
import { Font } from "components/font";
import { spacing } from "libs/styles/variables";
import { ComponentProps, forwardRef, memo, ReactNode } from "react";
import styled from "styled-components";
import { CheckBox } from ".";

type Props = {
  children: ReactNode;
  block?: boolean;
} & ComponentProps<typeof CheckBox>;

export const CheckBoxLabel = memo(
  forwardRef<HTMLInputElement, Props>(
    ({ children, block = false, ...otherProps }, ref) => {
      return (
        <Base $block={!!block}>
          <CheckBox {...otherProps} ref={ref} />
          <span>{children}</span>
        </Base>
      );
    },
  ),
);
CheckBoxLabel.displayName = "CheckBoxLabel";

const Base = styled.label<{ $block: boolean }>`
  align-items: center;
  color: ${Colors.black56};
  display: inline-flex;
  font-size: ${Font.fontSizeXs};
  padding-right: ${spacing.xs};
  ${({ $block }) => $block && "display: block; width: 100%;"};

  span {
    margin-left: ${spacing.sm};
  }
`;

"use client";

import { Card } from "components/ui/Card";
import { CardSection } from "components/ui/Card/CardSection";
import { H2 } from "components/ui/H";
import { LinkButton } from "components/ui/LinkButton";
import { Stack } from "components/ui/Stack";

export const SignUpNavigationCard = () => {
  return (
    <div className="mr-auto ml-auto w-80 text-center">
      <Card>
        <CardSection>
          <Stack spacing="xl">
            <H2>まだアカウントを持っていない方</H2>
            <LinkButton block href="/delivery_clients/new">
              新規登録画面へ
            </LinkButton>
          </Stack>
        </CardSection>
      </Card>
    </div>
  );
};
